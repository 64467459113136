// Styles
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
// Packages
import React, { useState } from "react";
import moment from "moment";
// Components
import BigCalendar from "react-big-calendar-like-google";
import EventModal from "../Calendar/modals/EventModal";
import { useDispatch, useSelector } from "react-redux";
import CustomToolbar from "../Body/Venues/TabsSection/Calender/Toolbar";
// Contexts
import { useEventContext } from "../../Contexts/CalendarEventContext";
import "../../Components/Calendar/fields/DateTimePicker.css";

moment.locale("en");
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const allViews = Object.keys(BigCalendar.Views).map(
  (k) => BigCalendar.Views[k]
);

export default function Calender({ calendarEvents, isVenuePublic }) {
  const { events, handleClickOpen } = useEventContext();
  const { eventsClick, handleEventClick } = useEventContext();
  const [showModal, setShowMoreState] = useState(false);
  const authToken = useSelector((state) => state.Auth.authToken);
  const components = {
    event: (props) => {
      if (props.event.is_booked) {
        return <div style={{ background: "#3174ad" }}>{props.event.title}</div>;
      } else {
        return <div style={{ background: "#c3bc75" }}>{props.event.title}</div>;
      }
    },
    toolbar: CustomToolbar,
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col p-2">
            <EventModal />
          </div>
        </div>
      </div>
      <BigCalendar
        components={components}
        popup
        selectable
        events={calendarEvents.map((item) => {
          if (!isVenuePublic) {
            return showEvent(item);
          } else {
            if (item.is_booked && isVenuePublic) {
              return showEvent(item);
            }
            return null;
          }
        })}
        defaultView="month"
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={authToken ? (event) => handleEventClick(event) : null}
        onSelectSlot={(slotInfo) => {
          const data = {
            start: slotInfo.start,
            end: slotInfo.end,
          };
          handleClickOpen(data);
        }}
        onShowMore={(calendarEvents) =>
          setShowMoreState({ showModal: true, calendarEvents })
        }
        showAllEvents={true}
        style={{ height: 500 }}
      />
    </div>
  );

  function showEvent(item) {
    return {
      id: item.id,
      start: moment.utc(item.start_time).toDate(),
      end: moment.utc(item.end_time).toDate(),
      title: item.introduction,
      is_booked: item.is_booked,
    };
  }
}
