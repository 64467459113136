import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllArtistsAction } from "../../../../Redux/Action/Artists";
import DummyImage from "../../../../Assets/noimg.jpg";
import Pagination from "../../../Pagination/Pagination";

const ArtistListingForBid = ({ openBidingPopup }) => {
  const dispatch = useDispatch();
  const artistsList = useSelector((state) => state.Artists.artists);
  const total_artists = useSelector((state) => state.Artists.total_artists);
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [currentPage, setCurrentPage] = useState(1);
  const [artistsPerPage] = useState(10);
  const [filter, SetFilters] = useState({});

  const selectArtist = (id) => {
    openBidingPopup(id);
  };

  const paginate = (pageNumber, filter) => {
    if (filter === null) {
      SetFilters({});
    } else {
      filter.page = pageNumber;
    }
    dispatch(GetAllArtistsAction(authToken, filter));
    setCurrentPage(pageNumber);
  };
  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };
  const SubmitFilters = () => {
    dispatch(GetAllArtistsAction(authToken, filter));
  };
  useEffect(() => {
    dispatch(GetAllArtistsAction(authToken, {}));
  }, []);

  return (
    <section className="venue-events">
      <div className="container">
        <div className="row ">
          <div className="col-md-6">
            <input
              type="search"
              placeholder="Search"
              name="search"
              onChange={handleFilter}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  SubmitFilters();
                }
              }}
              className=" form-control event-search-input"
            />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12">
            <Pagination
              currentPage={currentPage}
              artistsPerPage={artistsPerPage}
              totalArtists={total_artists}
              paginate={paginate}
              filter={filter}
            />
          </div>
        </div>
        <div className="row py-3">
          <div className="col-md-12">
            {artistsList.length && !loader ? (
              <div>
                <div className="row">
                  {artistsList?.map((item) => {
                    return (
                      <div key={item.id} className=" mb-3">
                        <div className="bid-cards">
                          <div className="row g-0 align-items-center">
                            <div className="col-md-6">
                              <img
                                src={item?.image ? item.image : DummyImage}
                                className="venue-event-img p-3"
                                alt="..."
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="card-body">
                                <div className="table-responsive mx-4 ">
                                  <table className=" table table-bordered mt-2">
                                    <tbody>
                                      <tr>
                                        <th className="text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="23"
                                            height="23"
                                            fill="currentColor"
                                            className="bi bi-music-note-beamed mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"
                                            />
                                            <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z" />
                                          </svg>
                                          Artist
                                        </th>
                                        <td className="text-capitalize">
                                          {item.name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="23"
                                            height="23"
                                            fill="currentColor"
                                            className="bi bi-music-note mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M9 13c0 1.105-1.12 2-2.5 2S4 14.105 4 13s1.12-2 2.5-2 2.5.895 2.5 2z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M9 3v10H8V3h1z"
                                            />
                                            <path d="M8 2.82a1 1 0 0 1 .804-.98l3-.6A1 1 0 0 1 13 2.22V4L8 5V2.82z" />
                                          </svg>
                                          Artist Type
                                        </th>
                                        <td className="text-capitalize">
                                          {item.artist_type
                                            ? item.artist_type?.name
                                            : "NA"}
                                        </td>
                                      </tr>

                                      <tr>
                                        <th className=" text-danger">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            className="bi bi-currency-exchange mx-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                                          </svg>
                                          Rate
                                        </th>
                                        <td>
                                          {item.rate_from
                                            ? item.rate_from
                                            : "NA"}
                                          -{item.rate_to ? item.rate_to : "NA"}
                                        </td>
                                      </tr>
                                      <tr className="text-end">
                                        <td className="" colSpan={2}>
                                          <button
                                            onClick={() => selectArtist(item)}
                                            className="btn btn-danger d-block w-100 px-5"
                                          >
                                            <span>Invite Artist</span>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtistListingForBid;
