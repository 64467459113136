import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GettingEventBasedOnIdPublicAction } from "../../Redux/Action/UpcomingEvents";
import ContentIndividualEvent from "./ContentIndividualEvent";
import HeaderRandomImages from "./HeaderRandomImages";

const EventDetailsPublic = () => {
  const dispatch = useDispatch();
  const EventDetail = useSelector((state) => state.Events.indivualEventDetail);
  const authToken = useSelector((state) => state.Auth.authToken);

  const { id } = useParams();

  useEffect(() => {
    dispatch(GettingEventBasedOnIdPublicAction(id, authToken));
  }, [id]);

  return (
    <>
      {EventDetail?.image ? (
        <section
          className="hero d-flex align-items-center justify-content-start event-dynamic-img"
          style={{
            background: `url(${EventDetail?.image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></section>
      ) : (
        <HeaderRandomImages />
      )}
      <ContentIndividualEvent EventDetail={EventDetail} />
    </>
  );
};

export default EventDetailsPublic;
