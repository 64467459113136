import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "./ContentIndividualEvent.css";

const ContentIndividualEvent = ({ EventDetail }) => {
  return (
    <div id="content" className="container-fluid">
      <div className="row">
        <div className="component row-splitter initialized">
          <div className="container-fluid">
            <div className="module module--full-width">
              <div className="container">
                <div className="row" data-rowwrapper="1">
                  <div className="component content event-detail col-12 col-xs-12">
                    <div className="component-content">
                      <h1 className="event-detail--title field-eventname">
                        {" "}
                        <Link
                          className="text-black"
                          to={`/artist-detail/${EventDetail?.artist?.id}`}
                        >
                          {EventDetail?.artist?.name}
                        </Link>
                      </h1>
                      <div className="event-detail--disclaimer disclaimer"></div>
                      <div className="component event-date-list">
                        <div className="component-content">
                          <ul className="items">
                            <li className="item">
                              <div class="item__wrapper--left">
                                <div class="listCCard__place">
                                  <div class="listCCard__place__wrap">
                                    <Link
                                      className="text-black"
                                      to={`/venue-detail/${EventDetail?.venue?.id}`}
                                    >
                                      {EventDetail.venue?.name}
                                    </Link>
                                  </div>
                                </div>
                                <div class="listCCard__time">
                                  <div class="listCCard__time__wrap">
                                    {moment(EventDetail?.start_time).format(
                                      "MMMM Do YYYY, h a"
                                    )}
                                  </div>
                                </div>
                                <div class="listCCard__rate">
                                  <div class="listCCard__rate__wrap">
                                    Ticket price: ${EventDetail?.rate} USD
                                  </div>
                                </div>
                                <div class="listCCard__genre">
                                  <div class="listCCard__genre__wrap">
                                    {EventDetail?.genre?.map((gn) => (
                                      <span
                                        key={gn.id}
                                        className="badge bg-success"
                                        style={{ marginRight: "2px" }}
                                      >
                                        {gn.name}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="field-eventdescription">
                        <p
                          className="MsoNormal"
                          style={{
                            margin: "0in",
                            fontSize: "12pt",
                            fontFamily: "Aptos, sans-serif",
                            color: "#000000",
                          }}
                        >
                          <span style={{ fontFamily: "Arial, sans-serif" }}>
                            {EventDetail?.description}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="component content rich-text col-xs-12"
                    data-uniqueid="page_content-417dcda9097b466085eaba4c4500f1e4"
                  >
                    <div className="component-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentIndividualEvent;
