import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import {
  Artistsoptions,
  DisplayValidationErrors,
  errorMessages,
} from "../../../Utilities";
import { useForm, Controller, set } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetAllGener } from "../../../Redux/Action/MasterData";
import { GetAllPublicArtistListAction } from "../../../Redux/Action/Artists";
import Select from "react-select";
import moment from "moment";

import { useEventContext } from "../../../Contexts/CalendarEventContext";

export default function AddEventForm() {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start: moment().format("YYYY-MM-DDTHH:mm"),
      genre: [],
    },
  });

  const hideDisplayArtistDropdown = () => {
    setIsPublic(!isPublic);
    setIsBidable(!isBidable);
  };
  const authToken = useSelector((state) => state.Auth.authToken);

  const dispatch = useDispatch();
  const generList = useSelector((state) => state.Master.Gener);
  const artistList = useSelector(
    (state) => state.Artists.indivualUserPublicArtists
  );
  const { saveEvent, handleClose, selectedEvent } = useEventContext();
  const [introduction, setIntroduction] = React.useState("");
  const [description, setDesc] = React.useState("");
  const [minRate, setMinRate] = React.useState("");
  const [maxRate, setMaxRate] = React.useState("");
  const [genre, setGenre] = React.useState([]);
  const [artist, setArtist] = React.useState([]);
  const [image, setImage] = React.useState(null);
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isBidable, setIsBidable] = useState(false);

  const submit = async () => {
    try {
      const sent = await saveEvent({
        introduction,
        description,
        minRate,
        maxRate,
        genre,
        artist,
        image,
        isPublic,
        isBidable,
        startTime,
        endTime,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (selectedEvent) {
      setStartTime(selectedEvent.start);
      setEndTime(selectedEvent.end);
    }
  }, [selectedEvent]);
  useEffect(() => {
    dispatch(GetAllGener());
    dispatch(GetAllPublicArtistListAction(authToken, {}));
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <DialogTitle>Register New Event</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <label>Introduction</label>
            <Grid item xs={12}>
              <TextField
                autoFocus
                dense
                fullWidth
                multiline
                required={true}
                placeholder="Enter event introduction"
                maxRows={3}
                type="text"
                variant="outlined"
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />
            </Grid>
            <label>Description</label>
            <Grid item xs={12}>
              <TextField
                dense
                fullWidth
                required={true}
                variant="outlined"
                placeholder="Enter event description"
                type="text"
                multiline
                maxRows={3}
                value={description}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Grid>
            <label>Minimum Rate</label>
            <Grid item xs={12}>
              <TextField
                required={true}
                margin="dense"
                placeholder="Minimum Rate"
                type="number"
                fullWidth
                variant="outlined"
                value={minRate}
                onChange={(e) => setMinRate(Math.max(0, e.target.value))}
              />
            </Grid>
            <label>Maximum Rate</label>
            <Grid item xs={12}>
              <TextField
                required={true}
                margin="dense"
                placeholder="Maximum Rate"
                type="number"
                fullWidth
                variant="outlined"
                value={maxRate}
                onChange={(e) => setMaxRate(e.target.value)}
              />
            </Grid>
            <label>Genre</label>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="genre"
                rules={{ required: true }}
                render={({ field: { onChange, value = [], ref } }) => (
                  <Select
                    inputRef={ref}
                    options={generList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={generList.filter((genre) =>
                      value.includes(genre.id)
                    )}
                    onChange={(val) => onChange(val.map((item) => item.id))}
                    onFocus={setGenre(value)}
                    isMulti={true}
                    closeMenuOnSelect={false}
                  />
                )}
              />
              {errors.genre ? (
                <p style={{ color: "red" }}>Please select this filed</p>
              ) : (
                ""
              )}
            </Grid>

            {!isPublic && (
              <>
                <label>Artist</label>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="artist"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        inputRef={ref}
                        enable={false}
                        options={artistList}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={Artistsoptions.find((c) => c.name === name)}
                        onChange={(val) => onChange(artist.push(val.id))}
                      />
                    )}
                  />
                  {errors.artist ? (
                    <p style={{ color: "red" }}>Please select this field</p>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            )}
            <label>Picture</label>
            <Grid item xs={12}>
              <div className="container">
                <div className="material-textfield">
                  <input
                    placeholder="Upload Picture"
                    type="file"
                    className="form-control px-1"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>
            </Grid>
            <label>Start Date</label>
            <Grid item xs={12}>
              <div className="container">
                <div className="material-textfield">
                  <input
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="form-control px-1"
                    type="datetime-local"
                  />
                </div>
              </div>
            </Grid>
            <label>End Date</label>
            <Grid item xs={12}>
              <div className="container">
                <div className="material-textfield">
                  <input
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    className="form-control px-1"
                    type="datetime-local"
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="container">
                <div className="material-textfield">
                  <input
                    onClick={hideDisplayArtistDropdown}
                    type="checkbox"
                    className="login-field bidable-checkbox m-0"
                    {...register("isBidable")}
                  />
                  <label className="mx-3" style={{ color: "black" }}>
                    Allow artists to bid on event
                  </label>
                  {errors["isBidable"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            className="login-btn"
            style={{ background: "#dc3545", color: "white" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="login-btn"
            style={{ background: "#dc3545", color: "white" }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}
