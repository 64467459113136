import React, { useEffect } from "react";
//import { Calendar, momentLocalizer } from 'react-big-calendar'
import Calendar from "../../../../Components/Calendar/Calendar";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the CSS file
import { useDispatch, useSelector } from "react-redux";
import { GetAllCalendarEventForArtistAction } from "../../../../Redux/Action/Artists";
import EventProvider from "../../../../Contexts/CalendarEventContext";

const ArtistCalendar = ({ id, openEventDetailsPopup, isArtistPublic }) => {
  const dispatch = useDispatch();
  const calendarEvents = useSelector(
    (state) => state.Artists.CalenderEventsForArtist
  );
  const authToken = useSelector((state) => state.Auth.authToken);

  const handleEventClick = (event) => {
    openEventDetailsPopup(event);
  };

  useEffect(() => {
    dispatch(GetAllCalendarEventForArtistAction(id));
  }, []);

  return (
    <section className="review">
      <div className="container">
        <div className="row py-3">
          <div className="col-md-12">
            <EventProvider
              openEventDetailsPopup={openEventDetailsPopup}
              isArtistPublic={isArtistPublic}
            >
              <Calendar calendarEvents={calendarEvents} />
            </EventProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtistCalendar;
