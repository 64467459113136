import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif";
import EditForm from "./EditForm";
import { UpdateEventAction } from "../../../Redux/Action/UpcomingEvents";
import moment from "moment";

const options = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditEvents = ({ showModal, closeModal, cancel }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const events = useSelector((state) => state.Events.eventBasedOnId);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [isPublic, setIsPublic] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      start_time: moment(events?.start_time).format("YYYY-MM-DDTHH:mm"),
      end_time: moment(events?.end_time).format("YYYY-MM-DDTHH:mm"),
      description: events?.description,
      rate: events?.rate,
      rate_from: events?.rate_from,
      rate_to: events?.rate_to,
      introduction: events?.introduction,
    },
  });

  const hideDisplayArtistDropdown = () => {
    setIsPublic(!isPublic);
  };
  const submitHandler = (data) => {
    let val;
    if (data["image"]?.[0]) {
      val = Object?.values(data?.image);
    }
    const formData = new FormData();
    formData.append(
      "start_time",
      data?.start_time ? data["start_time"] : events.start
    );
    formData.append("end_time", data?.end_time ? data["end_time"] : events.end);
    if (val?.[0] && data?.image && data?.image !== events?.image) {
      formData.append("image", val[0]);
    }
    formData.append(
      "description",
      data?.description ? data["description"] : events.description
    );
    formData.append(
      "rate_from",
      data?.rate_from ? data["rate_from"] : events.rate_from
    );
    formData.append(
      "rate_to",
      data?.rate_to ? data["rate_to"] : events.rate_to
    );
    //formData.append("rate", data?.rate ? data["rate"] : events.rate);
    formData.append(
      "introduction",
      data?.introduction ? data["introduction"] : events.introduction
    );
    if (data.venue === undefined || data.venue == null || data.venue === "") {
      //no need to sedn venue
    } else {
      formData.append("venue", data.venue);
    }
    if (data.genre === undefined || data.genre == null || data.genre === "") {
      //no need to send genre
    } else {
      formData.append("genre", JSON.stringify(data["genre"]));
    }
    if (!isPublic) {
      formData.append("artist", data["artist"]);
    }

    formData.append("public_bidding", data["isBidable"]);

    if (
      data.artist === undefined ||
      data.artist == null ||
      data.artist === ""
    ) {
      //no need to send genre
    } else {
      formData.append("artist", data.artist);
    }

    dispatch(
      UpdateEventAction(formData, authToken, events.id, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type === "success") {
          reset();
          closeModal();
        }
      })
    );
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="edit-venue-page"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">Edit Events</p>

              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <EditForm
                  hideDisplayArtistDropdown={hideDisplayArtistDropdown}
                  options={options}
                  events={events}
                  Controller={Controller}
                  control={control}
                  register={register}
                  errors={errors}
                  isPublic={isPublic}
                />
              </div>
            </>

            <div className="submission-form-popup">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} alt="" />
                ) : (
                  "Update"
                )}
              </button>

              <button onClick={cancel} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default EditEvents;
