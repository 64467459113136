export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ApiEndpoint = {
  REGISTER: BASE_URL + "/register",
  LOGIN: BASE_URL + "/login",
  SEND_OTP: BASE_URL + "/send-otp",
  RESET_PASSWORD: BASE_URL + "/reset-password",
  CHANGE_PASSWORD: BASE_URL + "/change-password",
  EDIT_PROFILE: BASE_URL + "/user-detail",
  GET_USER_PROFILE: BASE_URL + "/user-detail",
  ADD_ADMIN_USER: BASE_URL + "/venue-invite-employee",
  ADD_ARTISTS: BASE_URL + "/artist",
  GET_ALL_ARTISTS: BASE_URL + "/artist-detail-public",
  ADD_VENUE: BASE_URL + "/venue",
  GET_ALL_VENUE: BASE_URL + "/venue-detail-public",
  GET_ALL_UPCOMING_EVENTS: BASE_URL + "/event-detail-public",
  GET_ARTIST_FOR_LOGGED_IN_USER: BASE_URL + "/artist",
  GET_VENUE_FOR_LOGGED_IN_USER: BASE_URL + "/venue",
  GET_EVENTS_FOR_LOGGED_IN_USER: BASE_URL + "/event",
  UPDATE_VENUE: BASE_URL + "/venue",
  DELETE_VENUE: BASE_URL + "/venue",
  UPDATE_ARTISTS: BASE_URL + "/artist",
  UPDATE_AGREEMENT: BASE_URL + "/agreement",
  SEND_AGREEMENT: BASE_URL + "/venue-ask-to-sign-agreement",
  ADD_EVENT: BASE_URL + "/event",
  UPDATE_EVENT: BASE_URL + "/event",
  DELETE_EVENT: BASE_URL + "/event",
  GET_ARTIST_FOR_LOGGED_IN_USER_PUBLIC: BASE_URL + "/artist-detail-public",
  ARTIST_FOLLOW: BASE_URL + "/artist-follow",
  ARTIST_UNFOLLOW: BASE_URL + "/artist-unfollow",
  GET_FOLLOWING_ARTIST_LIST: BASE_URL + "/artist-follow",
  GET_VENUE_BASED_ON_ID_PUBLIC: BASE_URL + "/venue-detail-public",
  GET_ALL_EVENTS_FOR_INDIVUAL_VENUE: BASE_URL + "/venue",
  GET_ALL_EVENTS_CALENDAR_PUBLIC: BASE_URL + "/venue",
  GET_VENUE_DETAILS_FOR_LOGGED_IN_USER: BASE_URL + "/venue",
  VENUE_FOLLOW: BASE_URL + "/venue-follow",
  VENUE_UNFOLLOW: BASE_URL + "/venue-unfollow",
  GET_OVERALL_REVIEW_FOR_VENUE: BASE_URL + "/venue",
  ARTIST_BIDDING: BASE_URL + "/request-artist-booking",
  GET_ALL_GIGS: BASE_URL + "/get-all-artist-bid",
  ACCEPT_GIG: BASE_URL + "/artist-venue-bid-action",
  REJECT_GIG: BASE_URL + "/artist-venue-bid-action",
  NEGOTITATE_GIG: BASE_URL + "/artist-venue-bid-action",
  GET_ALL_BIDS: BASE_URL + "/get-all-venue-bid",
  GET_BIDABLE_BIDS_FOR_ARTIST_MANAGER: BASE_URL + "/get-all-public-bid-event",
  ARTIST_BIDING_ON_EVENT: BASE_URL + "/request-venue-event-booking",
  GET_ARTIST_EVENTS_CALENDAR: BASE_URL + "/artist",
  GET_OVERALL_REVIEW_FOR_ARTIST: BASE_URL + "/artist",
  GET_EVENT_DETAIL_BASED_ON_ID_PUBLIC: BASE_URL + "/event-detail-public",
  ADD_REVIEW: BASE_URL,
  DELETE_ARTIST: BASE_URL + "/artist",
  POST_ARTIST_MEDIA: BASE_URL + "/artist-media-uploade",
  POST_VENUE_MEDIA: BASE_URL + "/venue-media-uploade",
  GET_ARTIST_MEDIA: BASE_URL + "/artist",
  DELETE_MEDIA_FILES: BASE_URL + "/artist-media-uploade",
  // add additional end point for media
  GET_VENUE_MEDIA: BASE_URL + "/venue",
  GET_VENUE_SEARCH_OPTION: BASE_URL + "/venue-search",
  GET_GENRE_SEARCH_OPTION: BASE_URL + "/genre-search",
  GET_ARTIST_TYPE_SEARCH_OPTION: BASE_URL + "/artist-type-search",
  GET_ALL_PUBLIC_ARTIST_LIST: BASE_URL + "/get-all-public-artist-list",
};
