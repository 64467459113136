import { takeLatest, put, all } from "redux-saga/effects";
import Actions from "../Action/Actions";
import { startLoader, stopLoader } from "../Action/LoadingActions";
import { SaveAuth } from "../Action/AuthAction";
import { ApiEndpoint } from "../../Utilities/ApiEndpoints";
import { SaveProfileDetails } from "../Action/ProfileAction";
import {
  SaveAllPublicArtistsAction,
  SaveAllArtistsAction,
  SaveAllArtistsForLoggedUserAction,
  SaveAllCalendarEventForArtistAction,
  SaveArtistsBasedOnId,
  SaveFollowingArtistListAction,
  SaveOverallReviewForArtistBasedOnIdAction,
  saveArtistPhotos,
  saveAudiosForArtistAction,
  saveVideosForArtistAction,
} from "../Action/Artists";
import {
  SaveAllCalendarEventForVenuePublicAction,
  SaveAllEventsForIndivaulVenueBasedOnId,
  SaveAllVenuesAction,
  SaveAllVenuesLoggedUserAction,
  SaveOverallReviewForVenueBasedOnIdAction,
  SaveVenueBasedOnId,
  saveVenuePhotos,
  saveVideosForVenueAction,
  saveAudiosForVenueAction,
  GetAllCalendarEventForVenuePublicAction,
} from "../Action/Venues";
import {
  SaveAllUpcomingEventsAction,
  SaveAllUpcomingEventsForLoggedUserAction,
  SaveEventBasedOnId,
  SaveEventBasedOnIdPublicAction,
} from "../Action/UpcomingEvents";
import {
  SaveAllBidsAction,
  SaveAllGigsAction,
  SaveBidableBidsAction,
} from "../Action/Bidding";

import { SaveAllGener } from "../Action/MasterData";

function* hitRegisterApiFn({ payload, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.REGISTER, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result.message.email[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      const data = {
        accessToken: result.data?.access,
        refreshToken: result.data?.refresh,
        profile: result.data,
      };
      yield put(SaveAuth(data));
      callback("success", "Your account is created successfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitLogiApiFn({ payload, callback, isSocialLogin }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.LOGIN, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(payload);
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      if (isSocialLogin) {
        callback("error", "Email not found, Please register first");
      } else {
        callback("error", result?.detail);
      }
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      const data = {
        accessToken: result.access,
        refreshToken: result.refresh,
        profile: result.user_data,
      };
      yield put(SaveAuth(data));
      callback("success", "login success");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitSendOtpApiFn({ payload, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.SEND_OTP, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message.email[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Otp sent to your Email.");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitResetPasswordApiFn({ payload, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.RESET_PASSWORD, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message.email[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Password reset successful !");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitChangePasswordApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.CHANGE_PASSWORD, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback(
        "success",
        "Password Changed successful, Please login with new password !"
      );
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitEditProfileApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.EDIT_PROFILE, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Profile updated succesfull");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetUserProfileApiFn({ auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.GET_USER_PROFILE, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
    }
    if (response.status > 499) {
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveProfileDetails(result.data));
    }
  } catch (err) {
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddAdminUserApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ADD_ADMIN_USER, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Admin user created succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddArtistsApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ADD_ARTISTS, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Created succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllArtistApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_ALL_ARTISTS}${stringfil}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllArtistsAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddVenuesApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ADD_VENUE, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Created succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllVenuesApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_ALL_VENUE}${stringfil}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllVenuesAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllUpcomingEventApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ALL_UPCOMING_EVENTS}${stringfil}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllUpcomingEventsAction(result.data.result));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllArtistForLoggedUserApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_FOR_LOGGED_IN_USER}${stringfil}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllArtistsForLoggedUserAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllPublicArtistListApiFn({ auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_ALL_PUBLIC_ARTIST_LIST}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllPublicArtistsAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllVenuesForLoggedUserApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_VENUE_FOR_LOGGED_IN_USER}${stringfil}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllVenuesLoggedUserAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllUpcomingEventForLoggedUserApiFn({ auth, filter }) {
  let stringfil = "";
  if (Object.keys(filter).length > 0) {
    Object.keys(filter).forEach((element) => {
      if (filter[element] !== "" && filter[element] !== undefined) {
        if (stringfil.includes("?")) {
          stringfil += `&${element}=${filter[element]}`;
        } else {
          stringfil = `?${element}=${filter[element]}`;
        }
      }
    });
  }

  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_EVENTS_FOR_LOGGED_IN_USER}${stringfil}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();

    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllUpcomingEventsForLoggedUserAction(result.data.result));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetIndivualVenueBasedOnIdApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_VENUE_FOR_LOGGED_IN_USER}/${payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveVenueBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUpdateVenueApiFn({ payload, auth, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.UPDATE_VENUE}/${id}`, {
      method: "PATCH",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitDeleteVenueApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.DELETE_VENUE}/${payload}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetIndivualArtistsBasedOnIdApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_FOR_LOGGED_IN_USER}/${payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveArtistsBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUpdateArtistsApiFn({ payload, auth, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.UPDATE_ARTISTS}/${id}`, {
      method: "PATCH",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddEventApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ADD_EVENT, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(GetAllCalendarEventForVenuePublicAction(result.data?.venue.id));
      callback("success", "Created succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetIndivualEventBasedOnIdApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_EVENTS_FOR_LOGGED_IN_USER}/${payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveEventBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUpdateEventApiFn({ payload, auth, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.UPDATE_EVENT}/${id}`, {
      method: "PATCH",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitDeleteEventApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.DELETE_EVENT}/${payload}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly Deleted");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetIndivualArtistsBasedOnIdPublicApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const headers = {
      "Content-Type": "application/json",
    };
    if (auth != null) {
      headers.Authorization = `Bearer ${auth}`;
    }
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_FOR_LOGGED_IN_USER_PUBLIC}/${payload}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveArtistsBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitFollowArtistApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());

    const response = yield fetch(ApiEndpoint.ARTIST_FOLLOW, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "You are following this artist");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUnFollowArtistApiFn({ id, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.ARTIST_UNFOLLOW}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Artist Unfollowed");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetFollwingArtistListFn({ auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.GET_FOLLOWING_ARTIST_LIST, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveFollowingArtistListAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetIndivualVenueBasedOnIdPublicApiFn({ payload, auth }) {
  const headers = {
    "Content-Type": "application/json",
  };
  if (auth != null) {
    headers.Authorization = `Bearer ${auth}`;
  }
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_VENUE_BASED_ON_ID_PUBLIC}/${payload}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveVenueBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err?.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllEventsForIndivualVenueBasedOnIdPublicApiFn({
  payload,
  auth,
}) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ALL_EVENTS_FOR_INDIVUAL_VENUE}/${payload}/upcoming-event`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllEventsForIndivaulVenueBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllCalendarEventsVenuePublicApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ALL_EVENTS_CALENDAR_PUBLIC}/${payload}/calendar`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllCalendarEventForVenuePublicAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetVenueDeatilBasedOnIdPrivateApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_VENUE_DETAILS_FOR_LOGGED_IN_USER}/${payload}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveVenueBasedOnId(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitFollowVenueApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.VENUE_FOLLOW, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "You are following this Venue");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUnFollowVenueApiFn({ id, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.VENUE_UNFOLLOW}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "get error");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Venue Unfollowed");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetOverallReviewBasedOnVenueIdApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_OVERALL_REVIEW_FOR_VENUE}/${payload}/review`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveOverallReviewForVenueBasedOnIdAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitArtistBiddingApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ARTIST_BIDDING, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Notification sent to the artist");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllGigsForLoggedInUserApiFn({ auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.GET_ALL_GIGS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllGigsAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGigNegotiateApiFn({ id, payload, auth, callback }) {
  console.log("this", payload);
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.NEGOTITATE_GIG}/${id}/bid`, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Your request for negotiation sent succesfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGigAceeptedByArtistManagerApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.ACCEPT_GIG}/${payload.bidId}/accepted`,
      {
        method: "PATCH",
        body: JSON.stringify({ artist: payload.artist }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Gig Accepted succesfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGigRejectedByArtistManagerApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.REJECT_GIG}/${payload.bidId}/rejected`,
      {
        method: "PATCH",
        body: JSON.stringify({ artist: payload.artist }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Gig Rejected succesfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllBidsForLoggedInUserApiFn({ auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.GET_ALL_BIDS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllBidsAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGigAceeptedByVenueManagerApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.ACCEPT_GIG}/${payload.bidId}/accepted`,
      {
        method: "PATCH",
        body: JSON.stringify({ venue: payload.venue }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Gig Accepted succesfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGigRejectedByVenueManagerApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.REJECT_GIG}/${payload.bidId}/rejected`,
      {
        method: "PATCH",
        body: JSON.stringify({ venue: payload.venue }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", result?.message?.old_password[0]);
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Gig Rejected succesfully");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetBidableEventsForArtistApiFn({ auth, artistId }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_BIDABLE_BIDS_FOR_ARTIST_MANAGER}?artistId=${artistId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveBidableBidsAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitBidOnEventByArtistManagerApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.ARTIST_BIDING_ON_EVENT, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Notification sent to the Event manager");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllCalendarEventsArtistApiFn({ payload }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_EVENTS_CALENDAR}/${payload}/calendar`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllCalendarEventForArtistAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetOverallReviewBasedOnArtistIdApiFn({ payload, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_OVERALL_REVIEW_FOR_ARTIST}/${payload}/review`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveOverallReviewForArtistBasedOnIdAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetEventDetailsBasedOnIdPublicApiFn({ id, auth }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_EVENT_DETAIL_BASED_ON_ID_PUBLIC}/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveEventBasedOnIdPublicAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddReviewApiFn({ id, auth, payload, callback }) {
  let string = "";
  if (payload.hasOwnProperty("venue_rating")) {
    string = "venue";
  }
  if (payload.hasOwnProperty("artist_rating")) {
    string = "artist";
  }

  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.ADD_REVIEW}/${string}/${id}/review`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Reveiw added successfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitDeleteArtistApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.DELETE_ARTIST}/${payload}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddVenueMediaApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.POST_VENUE_MEDIA, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Uploaded succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitAddArtistMediaApiFn({ payload, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(ApiEndpoint.POST_ARTIST_MEDIA, {
      method: "POST",
      body: payload,
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Uploaded succesfully!");
    }
  } catch (err) {
    callback("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

// **
function* hitGetPhotosForArtistApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_MEDIA}/${id}/photo`,
      {
        method: "GET",
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(saveArtistPhotos(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}
// **
function* hitGetVideosForArtistApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_MEDIA}/${id}/video`,
      {
        method: "GET",
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(saveVideosForArtistAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

// **
function* hitGetAudiosForArtistApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(
      `${ApiEndpoint.GET_ARTIST_MEDIA}/${id}/audio`,
      {
        method: "GET",
      }
    );
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(saveAudiosForArtistAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitdeleteMediaFilesApiFn({ id, auth, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.DELETE_MEDIA_FILES}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "succesfuly updated");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

// adding function to venue

function* hitGetPhotosForVenueApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_VENUE_MEDIA}/${id}/photo`, {
      method: "GET",
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status == 200 || response.status == 201) {
      yield put(saveVenuePhotos(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetVideosForVenueApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_VENUE_MEDIA}/${id}/video`, {
      method: "GET",
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(saveVideosForVenueAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAudiosForVenueApiFn({ id }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_VENUE_MEDIA}/${id}/audio`, {
      method: "GET",
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(saveAudiosForVenueAction(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitGetAllGenerOptionsFn() {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.GET_GENRE_SEARCH_OPTION}`, {
      method: "GET",
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      console.log("error", "Getting error while hitting api");
    }
    if (response.status > 499) {
      console.log("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      yield put(SaveAllGener(result.data));
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitUpdateAgreeemntApiFn({ payload, auth, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.UPDATE_AGREEMENT}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      if (payload["status"] === "Signed") {
        callback("success", "Agreement signed successfully");
      } else {
        callback("success", "succesfuly updated");
      }
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

function* hitSendAgreementApiFn({ payload, auth, id, callback }) {
  try {
    yield put(startLoader());
    const response = yield fetch(`${ApiEndpoint.SEND_AGREEMENT}/${id}/sent`, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    });
    const result = yield response.json();
    if (response.status >= 400 && response.status <= 499) {
      callback("error", "getting error while hitting api");
    }
    if (response.status > 499) {
      callback("error", "Something went wrong");
    }

    if (response.status === 200 || response.status === 201) {
      callback("success", "Notification sent to the artist");
    }
  } catch (err) {
    console.log("error", err.message);
  } finally {
    yield put(stopLoader());
  }
}

export function* WatcherFunction() {
  yield all([
    takeLatest(Actions.REGISTER_USER, hitRegisterApiFn),
    takeLatest(Actions.LOGIN_USER, hitLogiApiFn),
    takeLatest(Actions.SEND_OTP, hitSendOtpApiFn),
    takeLatest(Actions.RESET_PASSWORD, hitResetPasswordApiFn),
    takeLatest(Actions.CHANGE_PASSWORD, hitChangePasswordApiFn),
    takeLatest(Actions.EDIT_PROFILE, hitEditProfileApiFn),
    takeLatest(Actions.GET_USER_PROFILE_FROM_API, hitGetUserProfileApiFn),
    takeLatest(Actions.ADD_ADMIN_USER, hitAddAdminUserApiFn),
    takeLatest(Actions.ADD_ARTISTS, hitAddArtistsApiFn),
    takeLatest(Actions.GET_ALL_ARTISTS, hitGetAllArtistApiFn),
    takeLatest(Actions.ADD_VENUES, hitAddVenuesApiFn),
    takeLatest(Actions.GET_ALL_VENUES, hitGetAllVenuesApiFn),
    takeLatest(Actions.GET_ALL_UPCOMING_EVENT, hitGetAllUpcomingEventApiFn),
    takeLatest(
      Actions.GET_ALL_ARTISTS_FOR_LOGGED_USER,
      hitGetAllArtistForLoggedUserApiFn
    ),
    takeLatest(
      Actions.GET_ALL_PUBLIC_ARTIST_LIST,
      hitGetAllPublicArtistListApiFn
    ),
    takeLatest(
      Actions.GET_ALL_VENUE_FOR_LOGGED_USER,
      hitGetAllVenuesForLoggedUserApiFn
    ),
    takeLatest(
      Actions.GET_ALL_EVENTS_FOR_LOGGED_USER,
      hitGetAllUpcomingEventForLoggedUserApiFn
    ),
    takeLatest(
      Actions.GET_INDIVUAL_VENUE_BASED_ON_ID,
      hitGetIndivualVenueBasedOnIdApiFn
    ),
    takeLatest(Actions.EDIT_VENUES, hitUpdateVenueApiFn),
    takeLatest(Actions.DELETE_VENUES, hitDeleteVenueApiFn),
    takeLatest(
      Actions.GET_INDIVUAL_ARTISTS_BASED_ON_ID,
      hitGetIndivualArtistsBasedOnIdApiFn
    ),
    takeLatest(Actions.EDIT_ARTISTS, hitUpdateArtistsApiFn),
    takeLatest(Actions.ADD_EVENT, hitAddEventApiFn),
    takeLatest(
      Actions.GET_INDIVUAL_EVENT_BASED_ON_ID,
      hitGetIndivualEventBasedOnIdApiFn
    ),
    takeLatest(Actions.EDIT_UPCOMING_EVENT, hitUpdateEventApiFn),
    takeLatest(Actions.DELETE_UPCOMING_EVENT, hitDeleteEventApiFn),
    takeLatest(
      Actions.GET_INDIVUAL_ARTISTS_BASED_ON_ID_PUBLIC,
      hitGetIndivualArtistsBasedOnIdPublicApiFn
    ),
    takeLatest(Actions.FOLLOW_ARTIST, hitFollowArtistApiFn),
    takeLatest(Actions.UNFOLLOW_ARTIST, hitUnFollowArtistApiFn),
    takeLatest(Actions.GET_FOLLOWING_ARTIST_LIST, hitGetFollwingArtistListFn),
    takeLatest(
      Actions.GET_INDIVUAL_VENUE_BASED_ON_ID_PUBLIC,
      hitGetIndivualVenueBasedOnIdPublicApiFn
    ),
    takeLatest(
      Actions.GET_ALL_EVENTS_FOR_INDIVUAL_VENUE_PUBLIC,
      hitGetAllEventsForIndivualVenueBasedOnIdPublicApiFn
    ),
    takeLatest(
      Actions.GET_ALL_CALENDAR_EVENT_FOR_VENUE_PUBLIC,
      hitGetAllCalendarEventsVenuePublicApiFn
    ),
    takeLatest(
      Actions.GET_VENUE_DETAILS_BASED_ON_ID_FOR_LOGGED_IN_USER,
      hitGetVenueDeatilBasedOnIdPrivateApiFn
    ),
    takeLatest(Actions.FOLLOW_VENUE, hitFollowVenueApiFn),
    takeLatest(Actions.UNFOLLOW_VENUE, hitUnFollowVenueApiFn),
    takeLatest(
      Actions.GET_OVERALL_REVIEW_BASED_ON_VENUE_ID,
      hitGetOverallReviewBasedOnVenueIdApiFn
    ),
    takeLatest(Actions.BIDDING_ARTIST, hitArtistBiddingApiFn),
    takeLatest(
      Actions.GET_ALL_GIGS_FOR_USER,
      hitGetAllGigsForLoggedInUserApiFn
    ),
    takeLatest(Actions.NEGOTIATE_GIG_PRICE, hitGigNegotiateApiFn),
    takeLatest(
      Actions.ACCEPT_GIG_ARTIST_MANAGER,
      hitGigAceeptedByArtistManagerApiFn
    ),
    takeLatest(
      Actions.REJECT_GIG_ARTIST_MANAGER,
      hitGigRejectedByArtistManagerApiFn
    ),
    takeLatest(Actions.GET_MY_BIDS, hitGetAllBidsForLoggedInUserApiFn),
    takeLatest(
      Actions.ACCEPT_GIG_VENUE_MANAGER,
      hitGigAceeptedByVenueManagerApiFn
    ),
    takeLatest(
      Actions.REJECT_GIG_VENUE_MANAGER,
      hitGigRejectedByVenueManagerApiFn
    ),
    takeLatest(
      Actions.GET_ALL_BIDABLE_EVENTS,
      hitGetBidableEventsForArtistApiFn
    ),
    takeLatest(
      Actions.BID_ON_EVENT_BY_ARTIST_MANAGER,
      hitBidOnEventByArtistManagerApiFn
    ),
    takeLatest(
      Actions.GET_ALL_CALENDAR_EVENT_FOR_ARTIST,
      hitGetAllCalendarEventsArtistApiFn
    ),
    takeLatest(
      Actions.GET_OVERALL_REVIEW_BASED_ON_ARTIST_ID,
      hitGetOverallReviewBasedOnArtistIdApiFn
    ),
    takeLatest(
      Actions.GET_EVENT_DETAILS_BASED_ON_ID,
      hitGetEventDetailsBasedOnIdPublicApiFn
    ),
    takeLatest(Actions.ADD_REVIEW, hitAddReviewApiFn),
    takeLatest(Actions.DELETE_ARTISTS, hitDeleteArtistApiFn),
    takeLatest(Actions.UPLOAD_VENUE_MEDIA, hitAddVenueMediaApiFn),
    takeLatest(Actions.UPLOAD_ARTIST_MEDIA, hitAddArtistMediaApiFn),
    takeLatest(Actions.GET_PHOTOS_FOR_ARTIST, hitGetPhotosForArtistApiFn),
    takeLatest(Actions.GET_VIDEOS_FOR_ARTIST, hitGetVideosForArtistApiFn),
    takeLatest(Actions.GET_AUDIOS_FOR_ARTIST, hitGetAudiosForArtistApiFn),
    takeLatest(Actions.DELETE_MEDIA_FILES, hitdeleteMediaFilesApiFn),
    // watcher for venue
    takeLatest(Actions.GET_PHOTOS_FOR_VENUE, hitGetPhotosForVenueApiFn),
    takeLatest(Actions.GET_VIDEOS_FOR_VENUE, hitGetVideosForVenueApiFn),
    takeLatest(Actions.GET_AUDIOS_FOR_VENUE, hitGetAudiosForVenueApiFn),

    // Master data
    takeLatest(Actions.GET_ALL_GENER, hitGetAllGenerOptionsFn),

    // Agreement
    takeLatest(
      Actions.SAVE_AGREEMENT_CONTENT_VENUE_MANAGER,
      hitUpdateAgreeemntApiFn
    ),
    takeLatest(
      Actions.SEND_AGREEMENT_CONTENT_VENUE_MANAGER,
      hitSendAgreementApiFn
    ),
  ]);
}
