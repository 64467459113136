import React, { useEffect, useState } from "react";
import Filter from "../../Components/Body/Filter";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUpcomingEventsAction } from "../../Redux/Action/UpcomingEvents";
import Spinner from "../../Assets/spinner.svg";
import IndivualEvents from "../../Components/Body/UpcomingEvents/IndivualEvents";
const UpcomingEvents = () => {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const EventList = useSelector((state) => state.Events.upcomingEvents);
  const loader = useSelector((state) => state.Loading.loading);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const authToken = useSelector((state) => state.Auth.authToken);

  const [filter, SetFilters] = useState({
    genre__id__in: "",
    venue__id__in: "",
    venue__venue_type__in: "",
    food_beverage__in: "",
    search: "",
    from: "",
    to: "",
    radius: "",
    review_rating: 0,
    rate_from: "",
    rate_to: "",
    only_venues_follow: false,
    only_artists_follow: false,
  });

  const filterButton = () => {
    setIsActive(!isActive);
  };
  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };

  const hitFilters = (data) => {
    const fill = {
      genre__id__in: data.genre,
      venue__id__in: data.venue,
      venue__venue_type__in: data.venueType,
      food_beverage__in: data.beverageType,
      from: data.from,
      to: data.to,
      radius: data.radius,
      review_rating: Number(data.rating).toFixed(1),
      rate_from: data.minPrice,
      rate_to: data.maxPrice,
      only_artists_follow: data.onlyArtistsFollow,
      only_venues_follow: data.onlyVenuesFollow,
      user_latitude: userLocation.lat,
      user_longitude: userLocation.lng,
    };
    SetFilters({ ...filter, ...fill });
    dispatch(GetAllUpcomingEventsAction(authToken, fill));
  };
  const SubmitFilters = () => {
    dispatch(GetAllUpcomingEventsAction(authToken, filter));
  };

  const clearFilters = () => {
    SetFilters({
      genre__id__in: "",
      venue__id__in: "",
      venue__venue_type__in: "",
      food_beverage__in: "",
      search: "",
      from: "",
      to: "",
      radius: "",
      review_rating: 0,
      rate_from: "",
      rate_to: "",
      only_artists_follow: false,
      only_venues_follow: false,
    });
    dispatch(GetAllUpcomingEventsAction(authToken, {}));
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error occurred while fetching user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser!");
    }

    dispatch(GetAllUpcomingEventsAction(authToken, {}));
  }, []);

  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}
      <Filter
        hitFilters={hitFilters}
        isActive={isActive}
        filterButton={filterButton}
        clearFilters={clearFilters}
        isComingEventsPage={true}
      />
      <section id="up_come_events">
        <div className=" container-fluid py-5">
          <div className="">
            <div onClick={filterButton} className="filter-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-funnel"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
              </svg>
            </div>
          </div>
          <section className=" artist-list px-3">
            <div className="container-fluid px-5">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6 artist-heading">
                  <h3 className="text-danger">Upcoming Events</h3>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <input
                    type="search"
                    placeholder="Search"
                    name="search"
                    onChange={handleFilter}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        SubmitFilters();
                      }
                    }}
                    className=" form-control event-search-input"
                  />
                  <button
                    type="button"
                    onClick={SubmitFilters}
                    className="btn event-search-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                </div>
                {EventList.length && !loader ? (
                  <div className="inner-artists mt-4">
                    <div className="row">
                      {EventList?.map((item) => {
                        return <IndivualEvents key={item.id} events={item} />;
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </div>
      </section>

      {!loader && EventList.length === 0 ? (
        <>
          <div className="row  ">
            <div className="col-md-12  text-center">
              <h4>No data found</h4>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default UpcomingEvents;
