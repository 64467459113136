import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllVenueAction } from "../../Redux/Action/Venues";
import Spinner from "../../Assets/spinner.svg";
import IndivualVenue from "../../Components/Body/Venues/IndivualVenue";
import Filter from "../../Components/Body/Filter";
import Pagination from "../../Components/Pagination/Pagination";

const Venues = () => {
  const dispatch = useDispatch();
  const venues = useSelector((state) => state.Venues.venues);
  const total_venues = useSelector((state) => state.Venues.total_venues);
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [isActive, setIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage] = useState(10);

  const paginate = (pageNumber, filter) => {
    if (filter === null) {
      filter = {};
    } else {
      filter.page = pageNumber;
    }
    dispatch(GetAllVenueAction(authToken, filter));
    setCurrentPage(pageNumber);
  };
  const [filter, SetFilters] = useState({
    id__in: "",
    venue_type__in: "",
    food_beverage__in: "",
    search: "",
    from: "",
    to: "",
    radius: "",
    review_rating: 0,
    rate_from: "",
    rate_to: "",
    only_venues_follow: false,
  });
  const hitFilters = (data) => {
    const fill = {
      id__in: data.venue,
      venue_type__in: data.venueType,
      food_beverage__in: data.beverageType,
      from: data.from,
      to: data.to,
      radius: data.radius,
      review_rating: Number(data.rating).toFixed(1),
      rate_from: data.minPrice,
      rate_to: data.maxPrice,
      only_venues_follow: data.onlyVenuesFollow,
      user_latitude: userLocation.lat,
      user_longitude: userLocation.lng,
    };
    dispatch(GetAllVenueAction(authToken, fill));
    SetFilters({ ...filter, ...fill });
  };
  const SubmitFilters = () => {
    dispatch(GetAllVenueAction(authToken, filter));
  };
  const filterButton = () => {
    setIsActive(!isActive);
  };
  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };

  const clearFilters = () => {
    SetFilters({
      id__in: "",
      venue_type__in: "",
      food_beverage__in: "",
      search: "",
      from: "",
      to: "",
      radius: "",
      review_rating: 0,
      rate_from: "",
      rate_to: "",
      only_venues_follow: false,
    });
    dispatch(GetAllVenueAction(authToken, {}));
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error occurred while fetching user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser!");
    }
    dispatch(GetAllVenueAction(authToken, {}));
  }, []);

  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}
      <Filter
        hitFilters={hitFilters}
        isActive={isActive}
        filterButton={filterButton}
        clearFilters={clearFilters}
        isVenuePage={true}
      />

      <section className=" py-5 artist-list">
        <div className="container-fluid px-5">
          <div className="">
            <div onClick={filterButton} className="filter-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-funnel"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
              </svg>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 artist-heading">
              <h3 className="text-danger">Venues</h3>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <input
                type="search"
                placeholder="Search"
                name="search"
                onChange={handleFilter}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    SubmitFilters();
                  }
                }}
                className=" form-control event-search-input"
              />
              <button
                type="button"
                onClick={SubmitFilters}
                className="btn event-search-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
            </div>
            <div className="col-md-8">
              <Pagination
                currentPage={currentPage}
                artistsPerPage={venuesPerPage}
                totalArtists={total_venues}
                paginate={paginate}
                filter={filter}
              />
            </div>
            {venues.length && !loader ? (
              <div className="inner-artists mt-4">
                <div className="row">
                  {venues?.map((item) => {
                    return <IndivualVenue key={item.id} venue={item} />;
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {!loader && venues.length === 0 ? (
        <>
          <div className="row  ">
            <div className="col-md-12  text-center">
              <h4>No data found</h4>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Venues;
