import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Artistsoptions,
  DisplayValidationErrors,
  Genreoptions,
  Venueoptions,
  errorMessages,
} from "../../../Utilities";
import toast, { Toaster } from "react-hot-toast";
import LoaderImg from "../../../Assets/loader.gif";
import { AddEventAction } from "../../../Redux/Action/UpcomingEvents";
import Select from "react-select";
import moment from "moment";
import { GetAllGener } from "../../../Redux/Action/MasterData";
import { GetAllArtistsAction } from "../../../Redux/Action/Artists";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddEvent = ({ showModal, closeModal, cancel, selectedDate }) => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.Loading.loading);
  const authToken = useSelector((state) => state.Auth.authToken);
  const venueDetail = useSelector((state) => state.Venues.venueBasedOnId);
  const generList = useSelector((state) => state.Master.Gener);
  const artistList = useSelector((state) => state.Artists.artists);

  const [isPublic, setIsPublic] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      start: moment(selectedDate).format("YYYY-MM-DDTHH:mm"),
      venue: venueDetail?.name ? venueDetail.name : selectedDate?.name,
      genre: [],
    },
  });

  const hideDisplayArtistDropdown = () => {
    setIsPublic(!isPublic);
  };

  const submitHandler = (data) => {
    const val = Object?.values(data.image);
    const formData = new FormData();
    formData.append("start_time", data["start_time"]);
    formData.append("end_time", data["end_time"]);
    if (val[0]) {
      formData.append("image", val[0]);
    }
    formData.append("introduction", data["introduction"]);
    formData.append("description", data["description"]);
    formData.append("rate_from", data["rate_from"]);
    formData.append("rate_to", data["rate_to"]);
    //formData.append("rate", data["rate"]);
    formData.append(
      "venue",
      venueDetail?.id ? venueDetail.id : selectedDate?.id
    );
    formData.append("genre", JSON.stringify(data["genre"]));
    if (!isPublic) {
      formData.append("artist", data["artist"]);
    }

    formData.append("public_bidding", data["isBidable"]);

    dispatch(
      AddEventAction(formData, authToken, (type, message) => {
        toast[type](message, {
          duration: 5000,
        });
        if (type === "success") {
          reset();
          closeModal();
        }
      })
    );
  };

  useEffect(() => {
    dispatch(GetAllGener());
    dispatch(GetAllArtistsAction(authToken, {}));
  }, []);

  useEffect(() => {
    // Set the initial value of the "start" field whenever selectedDate changes
    reset({
      start: moment(selectedDate).format("YYYY-MM-DDTHH:mm"),
      venue: venueDetail?.name ? venueDetail.name : selectedDate?.name,
    });
  }, [selectedDate]);

  return (
    <>
      <Modal
        open={showModal}
        fill={"true"}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="add-event-modal"
      >
        <Box sx={style} className="modal-form">
          <form onSubmit={handleSubmit(submitHandler)}>
            <>
              <p className=" text-danger text-uppercase h6">
                Register New Event
              </p>

              <button type="button" onClick={cancel} className="btn close-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
              <div className="row">
                <div className="col-md-6">
                  <label>Start Date</label>
                  <input
                    className="login-field"
                    type="datetime-local"
                    {...register("start_time", { required: true })}
                  />
                  {errors["start_time"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>End Date</label>
                  <input
                    className="login-field"
                    type="datetime-local"
                    {...register("end_time", { required: true })}
                  />
                  {errors["end_time"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Introduction</label>
                  <input
                    placeholder="Introduction"
                    className="login-field"
                    type="text"
                    {...register("introduction", {
                      required: true,
                    })}
                  />
                  {errors?.introduction?.type === "required" ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Description</label>
                  <input
                    placeholder="Description"
                    className="login-field"
                    type="text"
                    {...register("description", { required: true })}
                  />
                  {errors["description"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Minimum Rate</label>
                  <input
                    placeholder="Minium Rate"
                    className="login-field"
                    type="number"
                    {...register("rate_from", { required: true, min: 0 })}
                  />
                  {errors["rate_from"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6">
                  <label>Maximum Rate</label>
                  <input
                    placeholder="Maxiumum Rate"
                    className="login-field"
                    type="number"
                    {...register("rate_to", { required: true, min: 0 })}
                  />
                  {errors["rate_to"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
                {/*
                <div className="col-md-6">
                  <label>Rate</label>
                  <input
                    placeholder="Rate"
                    className="login-field"
                    type="number"
                    {...register("rate", { required: true })}
                  />
                  {errors["rate"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
                  
                <div className="col-md-6">
                  <label>Eventbrite Link</label>
                  <input
                    placeholder="Eventbrite Link"
                    className="login-field"
                    type="text"
                    {...register("eventbrite_link", { required: false })}
                  />
                  {errors["eventbrite_link"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
                */}

                <div className="col-md-6">
                  <label>Venue</label>
                  {/* <Controller
                      control={control}
                      name="venue"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={Venueoptions}
                          value={Venueoptions.find((c) => c.value === value)}           
                          onChange={(val) => onChange(val.value)}
                        />
                      )}
                    /> */}
                  <input
                    placeholder="Rate"
                    className="login-field"
                    type="text"
                    disabled
                    {...register("venue", { required: true })}
                  />
                  {errors.venue ? <p>Please select this field</p> : ""}
                </div>
                <div className="col-md-6">
                  <label>Genre</label>
                  <Controller
                    control={control}
                    name="genre"
                    rules={{ required: true }}
                    render={({ field: { onChange, value = [], ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={generList}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={generList.filter((genre) =>
                          value.includes(genre.id)
                        )}
                        onChange={(val) => onChange(val.map((item) => item.id))}
                        isMulti={true}
                        closeMenuOnSelect={false} // Keep the menu open after selection (useful for multi-select)
                      />
                    )}
                  />

                  {errors.genre ? <p>Please select this field</p> : ""}
                </div>
                {!isPublic && (
                  <div className="col-md-6">
                    <label>Artist</label>

                    <Controller
                      control={control}
                      name="artist"
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          inputRef={ref}
                          classNamePrefix="addl-class"
                          options={artistList}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          value={Artistsoptions.find((c) => c.name === name)}
                          onChange={(val) => onChange(val.id)}
                        />
                      )}
                    />
                    {errors.artist ? <p>Please select this field</p> : ""}
                  </div>
                )}

                <div className="col-md-6">
                  <label>Pitcture</label>
                  <input
                    placeholder="Picture"
                    className="login-field"
                    type="file"
                    {...register("image", { required: false })}
                  />
                  {errors["image"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>

                <div className="col-md-6 d-flex align-items-center justify-content-start m-auto  pt-3">
                  <input
                    placeholder="Picture"
                    className="login-field bidable-checkbox m-0"
                    onClick={hideDisplayArtistDropdown}
                    type="checkbox"
                    {...register("isBidable")}
                  />
                  <label className="mx-2 bidable-checkbox-heaeding text-dark">
                    Allow artists to bid on event
                  </label>
                  {errors["isBidable"] ? (
                    <DisplayValidationErrors
                      message={errorMessages.FILL_FIELD}
                    />
                  ) : null}
                </div>
              </div>
            </>

            <div className="submission-form-popup mt-3">
              <button disabled={loader} type="submit" className="login-btn ">
                {loader ? (
                  <img className="loader-image" src={LoaderImg} alt="" />
                ) : (
                  "Add Event"
                )}
              </button>

              <button onClick={cancel} type="button" className="login-btn">
                Cancel
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Toaster />
    </>
  );
};
export default AddEvent;
