import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DummyImage from "../../../Assets/noimg.jpg";

const IndivualGig = ({
  gig,
  isAgreement,
  openConfirmationPopup,
  openNegotiationPopup,
  openAgreementPopup,
}) => {
  const ActionHandler = (btntype) => {
    if (btntype === "Negotiation") {
      openNegotiationPopup(gig.id, gig.artist.id, gig.venue.id, btntype);
    } else if (btntype === "Agreement") {
      openAgreementPopup(
        gig.id,
        gig.artist.id,
        gig.venue.id,
        gig.agreement,
        btntype
      );
    } else {
      openConfirmationPopup(gig.id, gig.artist.id, gig.venue.id, btntype);
    }
  };
  const profile = useSelector((state) => state.Auth.profile);
  return (
    <>
      <div className="col-md-4 mb-2">
        <div className="pb-4 gig-profile-card">
          <div className="">
            <img
              src={gig.venue.image ? gig.venue.image : DummyImage}
              alt=""
              className="mygig-full-image"
            />
          </div>
          <div className="text-end pe-4">
            <img
              src={gig.artist.image ? gig.artist.image : DummyImage}
              alt=""
              className="mygig-rounded-image"
            />
          </div>
          <div className="d-flex align-items-baseline justify-content-center">
            <ul className="list-group">
              <li className=" d-flex   align-items-center">Venue name</li>
              <li className="d-flex  align-items-center">Event</li>
              <li className="m d-flex  align-items-center">Start date</li>
              <li className=" d-flex   align-items-center">End date</li>
              {(gig.status === "Pending" && gig.bid_by === "artist") ||
              gig.status === "Ongoing" ? (
                <li className="d-flex  align-items-center">My offer</li>
              ) : (
                ""
              )}
              {(gig.status === "Pending" && gig.bid_by === "venue") ||
              gig.status === "Ongoing" ? (
                <li className="d-flex  align-items-center">Venue offer</li>
              ) : (
                ""
              )}
              {gig.status === "Accepted" && isAgreement === false ? (
                <div>
                  <li className="d-flex  align-items-center">
                    Final gig price
                  </li>
                  <li className="d-flex  align-items-center">Status</li>
                </div>
              ) : (
                ""
              )}
              {gig.status === "Accepted" && isAgreement === true ? (
                <div>
                  <li className="d-flex  align-items-center">Status</li>
                </div>
              ) : (
                ""
              )}
              {gig.status === "Reject" ? (
                <li className="d-flex  align-items-center">Status</li>
              ) : (
                ""
              )}
            </ul>
            <ul className="list-group">
              <li className=" d-flex   align-items-center">
                <span className="gig-cards-para">
                  <Link
                    className="text-black"
                    to={`/venue-detail/${gig.venue.id}`}
                    target="_blank"
                  >
                    {gig.venue.name ? gig.venue.name : "NA"}
                  </Link>
                </span>
              </li>
              <li className=" d-flex   align-items-center">
                <span className="gig-cards-para">
                  <Link
                    className="text-black"
                    to={`/event-details/${gig.event.id}`}
                    target="_blank"
                  >
                    See event details
                  </Link>
                </span>
              </li>
              <li className="m d-flex  align-items-center">
                <span className="gig-cards-para">
                  {moment(gig.event.start_time).format("MMMM Do YYYY, h a")}
                </span>
              </li>
              <li className=" d-flex   align-items-center">
                <span className="gig-cards-para">
                  {moment(gig.event.end_time).format("MMMM Do YYYY, h a")}
                </span>
              </li>
              {(gig.status === "Pending" && gig.bid_by === "artist") ||
              gig.status === "Ongoing" ? (
                <li className="d-flex  align-items-center">
                  <span className="gig-cards-para">
                    {gig?.artist_band_bidding_amount}
                  </span>
                </li>
              ) : (
                ""
              )}
              {gig.status === "Pending" && gig.bid_by === "venue" ? (
                <li className="d-flex  align-items-center">
                  <span className="gig-cards-para">
                    {gig?.venue_bidding_offer_price_low} -{" "}
                    {gig?.venue_bidding_offer_price_high}
                  </span>
                </li>
              ) : (
                ""
              )}
              {gig.status === "Ongoing" ? (
                <li className="d-flex  align-items-center">
                  <span className="gig-cards-para">
                    {gig?.venue_bidding_amount
                      ? gig.venue_bidding_amount
                      : "NA"}
                  </span>
                </li>
              ) : (
                ""
              )}
              {gig.status === "Accepted" && isAgreement === false ? (
                <div>
                  <li className="d-flex  align-items-center">
                    <span className="gig-cards-para">
                      {gig?.accepted_bid_amount}
                    </span>
                  </li>
                  <li className="d-flex  align-items-center">
                    <span className="text-success fw-bold accept-status ">
                      Accepted
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-check-lg mb-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                      </svg>
                    </span>
                  </li>
                </div>
              ) : (
                ""
              )}
              {gig.status === "Accepted" &&
              isAgreement === true &&
              profile.artist_or_band ? (
                <div>
                  <li className="d-flex  align-items-center">
                    {gig.agreement.length > 0 &&
                    gig.agreement[0].status === "Ongoing" ? (
                      <span className="fw-bold" style={{ color: "orange" }}>
                        In progress
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          className="bi bi-check-lg mb-1"
                          viewBox="0 0 16 16"
                          style={{ textAlign: "center" }}
                        ></svg>
                      </span>
                    ) : (
                      ""
                    )}
                    {gig.agreement.length > 0 &&
                    gig.agreement[0].status === "Sent" ? (
                      <span className="fw-bold" style={{ color: "blue" }}>
                        Pending to be signed
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="red"
                          className="bi bi-check-lg mb-1"
                          viewBox="0 0 16 16"
                        ></svg>
                      </span>
                    ) : (
                      ""
                    )}
                    {gig.agreement.length > 0 &&
                    gig.agreement[0].status === "Signed" ? (
                      <span className="fw-bold" style={{ color: "Green" }}>
                        Signed
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="red"
                          className="bi bi-check-lg mb-1"
                          viewBox="0 0 16 16"
                        ></svg>
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <div>
                    <button
                      onClick={() => ActionHandler("Agreement")}
                      className="gig-card-btn orange"
                      type="btn"
                    >
                      Agreement&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {gig.status === "Reject" ? (
                <li className="d-flex  align-items-center">
                  <span className="text-danger fw-bold reject-status ">
                    Closed
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-check-lg mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </li>
              ) : (
                ""
              )}

              <li className="d-flex  align-items-center"></li>
            </ul>
          </div>
          <div className="pt-3 text-center">
            {gig.status === "Pending" && gig.bid_by === "artist" ? (
              <span className=" fw-bold pending-status ">
                Sent
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-hourglass-split mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                </svg>
              </span>
            ) : (
              ""
            )}
            {gig.status === "Ongoing" ? (
              <div>
                {gig.venue_bidding_amount ? (
                  <button
                    onClick={() => ActionHandler("Accept")}
                    className="gig-card-btn green"
                    type="btn"
                  >
                    Accept
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
                <button
                  onClick={() => ActionHandler("Negotiation")}
                  className="gig-card-btn orange"
                  type="btn"
                >
                  Negotiation
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-file-earmark-diff"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 5a.5.5 0 0 1 .5.5V7H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V8H6a.5.5 0 0 1 0-1h1.5V5.5A.5.5 0 0 1 8 5zm-2.5 6.5A.5.5 0 0 1 6 11h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                  </svg>
                </button>
                <button
                  onClick={() => ActionHandler("Reject")}
                  className="gig-card-btn bg-danger"
                  type="btn"
                >
                  Close
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </div>
            ) : (
              ""
            )}
            {gig.status === "Pending" && gig.bid_by === "venue" ? (
              <button
                onClick={() => ActionHandler("Negotiation")}
                className="gig-card-btn orange"
                type="btn"
              >
                Negotiation
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-file-earmark-diff"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 5a.5.5 0 0 1 .5.5V7H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V8H6a.5.5 0 0 1 0-1h1.5V5.5A.5.5 0 0 1 8 5zm-2.5 6.5A.5.5 0 0 1 6 11h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndivualGig;
