import React from "react";
import { useSelector } from "react-redux";
import {
  Artistsoptions,
  DisplayValidationErrors,
  errorMessages,
} from "../../../Utilities";
import Select from "react-select";
const EditForm = ({
  register,
  errors,
  Controller,
  control,
  events,
  hideDisplayArtistDropdown,
  isPublic,
}) => {
  const generList = useSelector((state) => state.Master.Gener);
  const artistList = useSelector(
    (state) => state.Artists.indivualUserPublicArtists
  );
  const venueList = useSelector((state) => state.Venues.indivualUserVenues);
  return (
    <>
      <div className="col-md-6">
        <label>Start Date</label>
        <input
          className="login-field"
          type="datetime-local"
          {...register("start_time", { required: true })}
        />
        {errors["start_time"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>End Date</label>
        <input
          className="login-field"
          type="datetime-local"
          {...register("end_time", { required: true })}
        />
        {errors["end_time"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Introduction</label>
        <input
          placeholder="Introduction"
          className="login-field"
          type="text"
          {...register("introduction", {
            required: true,
          })}
        />
        {errors?.introduction?.type === "required" ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Description</label>
        <input
          placeholder="Description"
          className="login-field"
          type="text"
          {...register("description", { required: true })}
        />
        {errors["description"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Minimum Rate</label>
        <input
          placeholder="Minium Rate"
          className="login-field"
          type="number"
          {...register("rate_from", { required: true })}
        />
        {errors["rate_from"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>

      <div className="col-md-6">
        <label>Maximum Rate</label>
        <input
          placeholder="Maxiumum Rate"
          className="login-field"
          type="number"
          {...register("rate_to", { required: true })}
        />
        {errors["rate_to"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>
      {/*
      <div className="col-md-6">
        <label>Rate</label>
        <input
          placeholder="Rate"
          className="login-field"
          type="number"
          {...register("rate", { required: true })}
        />
        {errors["rate"] ? (
          <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
        ) : null}
      </div>
      */}

      <div className="col-md-6">
        <label>Venue</label>
        <Controller
          control={control}
          name="venue"
          rules={{ required: false }}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={venueList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={venueList.find((c) => c.name === name)}
              onChange={(val) => onChange(val.id)}
            />
          )}
        />
        {errors.venue ? <p>Please select this field</p> : ""}
      </div>

      <div className="col-md-6">
        <label>Genre</label>
        <Controller
          control={control}
          name="genre"
          rules={{ required: false }}
          render={({ field: { onChange, value = [], ref } }) => (
            <Select
              inputRef={ref}
              classNamePrefix="addl-class"
              options={generList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={generList.filter((genre) => value.includes(genre.id))}
              onChange={(val) => onChange(val.map((item) => item.id))}
              isMulti={true}
              closeMenuOnSelect={false}
            />
          )}
        />
        {errors.genre ? <p>Please select this field</p> : ""}
      </div>
      <div className="col-md-6">
        {!isPublic && (
          <>
            <label>Artist</label>
            <Controller
              control={control}
              name="artist"
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  inputRef={ref}
                  classNamePrefix="addl-class"
                  options={artistList}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={artistList.find((c) => c.name === name)}
                  onChange={(val) => onChange(val.id)}
                />
              )}
            />
          </>
        )}
        {errors.artist ? <p>Please select this field</p> : ""}
      </div>
      <div className="col-md-6">
        <label>Pitcture</label>
        <input
          placeholder="Picture"
          type="file"
          className="form-control px-1"
          {...register("image", { required: false })}
        />
      </div>
      <div className="container">
        <div className="material-textfield">
          <input
            placeholder="Picture"
            className="login-field bidable-checkbox m-0"
            onClick={hideDisplayArtistDropdown}
            type="checkbox"
            {...register("isBidable")}
          />
          <label className="mx-3" style={{ color: "black" }}>
            Allow artists to bid on event
          </label>
          {errors["isBidable"] ? (
            <DisplayValidationErrors message={errorMessages.FILL_FIELD} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EditForm;
