import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddArtists from "./AddArtists";
import {
  DeleteArtistAction,
  GetAllArtistsForLoggedUserAction,
} from "../../../Redux/Action/Artists";
import Spinner from "../../../Assets/spinner.svg";
import MyIndivualArtist from "../../../Components/Body/Artists/MyIndivualArtist";
import { useNavigate } from "react-router-dom";
import DeleteArtist from "./ArtistDelete";
const ArtistsList = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.Auth.authToken);
  const artistsList = useSelector((state) => state.Artists.indivualUserArtists);
  const loader = useSelector((state) => state.Loading.loading);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVenueId, setSelectedVenueId] = useState(null);

  const [filter, SetFilters] = useState({
    id__in: "",
    genre_ids: "",
    artist_type__in: "",
    search: "",
    review_rating: 0,
    only_artists_follow: false,
  });

  const SubmitFilters = () => {
    dispatch(GetAllArtistsForLoggedUserAction(authToken, filter));
  };
  const handleFilter = (e) => {
    const { name, value } = e.target;
    SetFilters({ ...filter, [name]: value });
  };
  const profile = useSelector((state) => state.Auth.profile);

  if (profile.artist_or_band !== true) {
    navigate("/upcoming_events");
  }

  const dispatch = useDispatch();
  //ADD artists modal
  const openModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    dispatch(GetAllArtistsForLoggedUserAction(authToken, {}));
  };
  const cancelAddModal = () => {
    setShowAddModal(false);
  };

  const openDeleteModal = (venueId) => {
    setSelectedVenueId(venueId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    dispatch(
      DeleteArtistAction(selectedVenueId, authToken, (type, message) => {
        if (type === "success")
          dispatch(GetAllArtistsForLoggedUserAction(authToken, {}));
      })
    );
  };
  const cancelDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(GetAllArtistsForLoggedUserAction(authToken, {}));
  }, []);

  return (
    <>
      {loader ? (
        <div className="spinner-loader">
          <img className="spinner" src={Spinner} alt="" />
        </div>
      ) : null}

      <section className=" py-5 artist-list">
        <div className="container-fluid px-5">
          <div className="row d-flex justify-content-center">
            <div className=" d-flex justify-content-between artist-heading">
              <h3 className="text-danger">Artists / Bands</h3>
              <button onClick={openModal} className="btn btn-outline-danger">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
                <span className="my-2">New Artist / Band </span>
              </button>
            </div>
            {artistsList?.length && !loader ? (
              <div className="inner-artists mt-4">
                <div className="row">
                  {artistsList?.map((item) => {
                    return (
                      <MyIndivualArtist
                        key={item.id}
                        artist={item}
                        openDeleteModal={openDeleteModal}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {!loader && artistsList?.length == 0 ? (
        <>
          <div className="row  ">
            <div className="col-md-12  text-center">
              <h4>No data found</h4>
            </div>
          </div>
        </>
      ) : null}
      <AddArtists
        cancelAddModal={cancelAddModal}
        showModal={showAddModal}
        closeModal={closeAddModal}
      />
      {/* wating untile reducer is not fill it will stop modal to open once values filed modal open */}

      <DeleteArtist
        cancel={cancelDeleteModal}
        showModal={showDeleteModal}
        closeModal={closeDeleteModal}
      />
    </>
  );
};

export default ArtistsList;
