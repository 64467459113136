import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { NegotiationGigPriceAction } from "../../../Redux/Action/Bidding";
import { Toaster, toast } from "react-hot-toast";
import {
  SaveAgreementContentByVeneuManger,
  SendAgreementContentByVeneuManger,
} from "../../../Redux/Action/Bidding";

const Agreement = ({ closeModal, initialValue, selectedBid, viewAdmin }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const authToken = useSelector((state) => state.Auth.authToken);

  const [dirty, setDirty] = useState(false);
  useEffect(() => setDirty(false), [initialValue]);
  const save = () => {
    if (editorRef.current) {
      let payload = null;
      const content = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      payload = {
        agreement_content: content,
      };
      dispatch(
        SaveAgreementContentByVeneuManger(
          selectedBid.agreement[0].id,
          payload,
          authToken,
          (type, message) => {
            toast[type](`${message}`, {
              duration: 5000,
            });
            closeModal();
          }
        )
      );
    }
  };
  const send = () => {
    dispatch(
      SendAgreementContentByVeneuManger(
        selectedBid.agreement[0].id,
        {},
        authToken,
        (type, message) => {
          toast[type](`${message}`, {
            duration: 5000,
          });
          closeModal();
        }
      )
    );
  };
  const sign = () => {
    let payload = { status: "Signed" };
    dispatch(
      SaveAgreementContentByVeneuManger(
        selectedBid.agreement[0].id,
        payload,
        authToken,
        (type, message) => {
          toast[type](`${message}`, {
            duration: 5000,
          });
          closeModal();
        }
      )
    );
  };
  return (
    <div className="row">
      <div className="col-md-10 mt-3 m-auto">
        <Editor
          apiKey="4lva7js16i6hnxwqoy0xz7chgp4tlojg4eu4s2r44yi4sp0o"
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            selector: "textarea", // change this value according to your html
            plugins: [
              "lists",
              "code",
              "image",
              "link",
              "charmap",
              "preview",
              "anchor",
              "table",
              "insertdatetime",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat",
            height: 900,
          }}
          initialValue={selectedBid.agreement[0].agreement_content}
          onDirty={() => setDirty(true)}
        />
      </div>
      <div className="col-md-10 mt-3 m-auto">
        {viewAdmin === "venue" ? (
          <div>
            <button
              type="button"
              onClick={save}
              className=" btn btn-success me-3"
            >
              Save agreement
            </button>
            <button
              type="button"
              onClick={send}
              className=" btn btn-success me-3"
            >
              Ask to sign the agreement
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
        {viewAdmin === "artist" ? (
          <div>
            {selectedBid.agreement[0].status === "Sent" ? (
              <button
                type="button"
                onClick={sign}
                className=" btn btn-success me-3"
              >
                Sign the agreement
              </button>
            ) : (
              ""
            )}
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Agreement;
